import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.video',
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_VIDEO']),
      search: 'video.search_keyword',
      filter: {
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        title: row.title,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'data.title', key: 'title' },
      ],
      card: {
        title: row => row.title,
        subtitle: row => row.brief,
        avatarPhoto: row => this.vm?.$helper.getVideoPhoto(row),
      },
      table: {
        data: <TableDataType>[
          {
            key: 'photo',
            label: 'data.photo',
            type: 'photo',
            placeholderIcon: 'fa fa-film',
            photo: row => this.vm?.$helper.getVideoPhoto(row),
          },
          {
            key: 'type',
            label: 'video.data.type',
            type: 'text',
            text: row => this.vm?.$t(`video.data.type.${row.type}`),
          },
          {
            key: 'title',
            label: 'data.title',
            copyable: true,
            type: 'text',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      create: {
        popupComponent: () => import('@/modules/video/views/videoList/videoListCreatePopup.vue'),
        disabledApply: (formData) => {
          if(window.helper.textEmpty(formData.title)) return true
          if(window.helper.textEmpty(formData.type)) return true
          return false
        },
        createApi: async (formData) => {
          return await this.vm?.$api.collection.videoApi.create(formData)
        },
        redirect: (result => ({
          name: `video-update`,
          params: { target: result.id },
        }))
      },
      dataAction: {
        update: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          label: 'action.edit',
          route: row => ({ name: 'video-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          removeTarget: row => row.name,
        },
        playVideo: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          label: 'action.play-video',
          icon: 'fa fa-film',
          buttonProperties: (row: any) => ({
            class: 'd-block',
          }),
          // @ts-ignore
          callback: row => this.vm.$previewVideo(row),
        },
      },
    }

  }
}

export default new listConfig()
