import Vue from 'vue'
import { EagleListConfigInterface } from '@/components/list/types/list'
import videoListConfig from 'modules/video/views/videoListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardVideoListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardVideoListConfig = videoListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardVideoListConfig.search,
      filter: this.standardVideoListConfig.filter,
      selectedData: this.standardVideoListConfig.selectedData,
      displayMode: this.standardVideoListConfig.displayMode,
      displayModeTypes: this.standardVideoListConfig.displayModeTypes,
      sort: this.standardVideoListConfig.sort,
      card: this.standardVideoListConfig.card,
      table: this.standardVideoListConfig.table,
    }

  }
}

export default new listConfig()
